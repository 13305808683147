import React, { useEffect, useState } from 'react'
import PersonProfile from './component/personProfile'
import PersonCalender from './component/personCalender'
import { getEmergencyContact, getSpecificProfile } from '../Services/ApiCalling'
import { useLocation } from 'react-router-dom'

function PersonProfileView() {

  const location = useLocation()
  console.log("location",location)
 let EMP_ID = location.state
  const [profileData, setProfileData] = useState();
  const [calendarData, setCalenderData] = useState();
  const [emgData, setEmgData] = useState();
  const [appType, setAppType] = useState();
  const [appStatus, setAppStatus] = useState();
  const [monthNo, setMonthNo] = useState();
  const [dayNo, setDayNo] = useState();

  const token = localStorage.getItem("LogIn_Token") 

  const getProfileData = async (token, EMP_ID, appType, appStatus, monthNo, dayNo) => {
    const res = await getSpecificProfile(token, EMP_ID, appType, appStatus, monthNo, dayNo)
    // setProfileData(res.data)
    setProfileData(res.showAllProfile)
    setCalenderData(res.data)
    setEmgData(res.emrgencyProfile)
    // console.log('respppkkkkk', res.emrgencyProfile);
    console.log("res.data",res.data);
    console.log("resresres",res);
  }
  useEffect(() => {
    getProfileData(token, EMP_ID, appType, appStatus, monthNo, dayNo);
  }, []);

  useEffect(() => {
    getProfileData(token, EMP_ID, appType, appStatus, monthNo, dayNo);
  }, [appType, appStatus, monthNo, dayNo]);


  return (
    <div>
      {console.log("calendarData",calendarData)}
      {profileData && (<PersonProfile profileData = {profileData} emgData = {emgData || null}/>)}
      {
      calendarData &&
       <PersonCalender 
       calendarData = {calendarData} 
       setAppType={setAppType} 
       setAppStatus={setAppStatus}
       setMonthNo={setMonthNo}
       setDayNo={setDayNo}
       />
      }
    </div>
  )
}

export default PersonProfileView
