import React, { useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";

const LeaveFilter = ({ setEmpName, from , to, setAppType, setAppStatus, setFrom, setTo}) => {

  const [dateTwo, setdateTwo] = useState(true);
  const [isFocused, setIsFocused] = useState(true);
  const [itemFocus, setItemFocus] = useState(true);

  const inputFocus = () => {
    setItemFocus(true);
  };

  const inputBlur = () => {
    setItemFocus(true);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(true);
  };

  const handleFocustwo = () => {
    setdateTwo(true);

  };
  const handleBlurtwo = () => {
    setdateTwo(true);
  };

  const handleDateChange = (date) => {
    setFrom(date);
  };

  const handleDateChangeTwo = (date) => {
    setTo(date);
  };

   const handleInputChange = (e) =>{
    setEmpName(e.target.value)
   }



  const leave = [
    { value: "WFH", label: "Work From Home" },
    { value: "LEAVE", label: "Leave" },
    { value: "OFFICIAL_TOUR", label: "OFFICIAL_TOUR" },
  ];
  const leavestatus = [
    { value: "APPROVED", label: "Approved" },
    { value: "PENDING", label: "Pending" },
    { value: "REJECT", label: "Rejected" },
  ];
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };
  return (
    <div>
      {/* Search Filter */}
      <div className="row filter-row">
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
          <div
            className={`input-block mb-3 form-focus ${
              itemFocus ? "focused" : ""
            } `}
          >
            <input
              type="text"
              className="form-control floating"
              onFocus={inputFocus}
              onBlur={inputBlur}
              onChange={handleInputChange}
              // placeholder="Employee Name"                     {yash's change}
            />
            <label className="focus-label">Employee Name</label>  {/*yash's change*/}
          </div>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
          <div className="input-block mb-3 form-focus select-focus">
            <Select
              options={leave}
              onChange={setAppType}
              placeholder="Work from home"
              styles={customStyles}
            />
            <label className="focus-label">Application Type</label>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
          <div className="input-block mb-3 form-focus select-focus">
            <Select
              options={leavestatus}
              onChange={setAppStatus}
              placeholder="Pending"
              styles={customStyles}
            />
            <label className="focus-label">Application Status</label>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
          <div
            className={`input-block mb-3 form-focus ${
              isFocused ? "focused" : ""
            }`}
          >
            <div className="cal-icon focused ">
              <DatePicker
                className="form-control floating datetimepicker"
                selected={from}
                onChange={handleDateChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                dateFormat="dd-MM-yyyy"
              />
            </div>
            <label className="focus-label">From Date</label>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
          <div
            className={`input-block mb-3 form-focus ${
              dateTwo ? "focused" : ""
            }`}
          >
            <div className="cal-icon">
              <DatePicker
                className="form-control floating datetimepicker "
                selected={to}
                onChange={handleDateChangeTwo}
                onFocus={handleFocustwo}
                onBlur={handleBlurtwo}
                dateFormat="dd-MM-yyyy"
              />
            </div>
            <label className="focus-label">To Date</label>
          </div>
        </div>
      </div>
      {/* /Search Filter */}
    </div>
  );
};

export default LeaveFilter;
