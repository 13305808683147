import React from "react";
import { Link } from "react-router-dom";
import { deleteHoliday } from "../../Services/ApiCalling";

const DeleteModal = ({Name, holiday_record, HolidayData, }) => {
  const token = localStorage.getItem("LogIn_Token");

  const removeHoliday = async (token, holiday_record) => {
    const resp = await deleteHoliday(token, holiday_record)
    await HolidayData(token);
    console.log(resp);
  }

  return (
    <>
      {/* Delete Performance Indicator Modal */}
      <div className="modal custom-modal fade" id="delete" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="form-header">
                <h3>{Name}</h3>
                <p>Are you sure want to delete?</p>
              </div>
              <div className="modal-btn delete-action">
                <div className="row">
                  <div className="col-6">
                    <Link to="#" className="btn btn-primary continue-btn"
                    data-bs-dismiss="modal"
                    onClick={ async () =>{
                      await removeHoliday(token, holiday_record);
                    }}
                    >
                      Delete
                    </Link>
                  </div>
                  <div className="col-6">
                    <Link
                      to="#"
                      data-bs-dismiss="modal"
                      className="btn btn-primary cancel-btn"
                    >
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Delete Performance Indicator Modal */}
    </>
  );
};

export default DeleteModal;
