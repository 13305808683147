import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import LeaveFilter from "./LeaveFilter";
// import "../../../../../assets/css/calendar.css";
import CalendarModal from "../../../components/modelpopup/CalendarModal";

function PersonCalender({ calendarData, setAppType, setAppStatus, setMonthNo, setDayNo }) {

    const [weekendsVisible, setWeekendVisible] = useState(true);


    // State to manage the selected values of the dropdowns
    const [leaveType, setLeaveType] = useState("LEAVE");
    const [status, setStatus] = useState("PENDING");

    // Event handler for the leave type dropdown
    const handleLeaveTypeChange = (e) => {
        setLeaveType(e.target.value);
        console.log("Selected Leave Type:", e.target.value); // For debugging
    };

    // Event handler for the status dropdown
    const handleStatusChange = (e) => {
        setStatus(e.target.value);
        console.log("Selected Status:", e.target.value); // For debugging
    };


    const finalArray = []
    //  console.log("calendarDatacalendarData",calendarData)
    
    calendarData.map((item) => {
        let from = Date.parse(item.REQ_FROM)
        let to = Date.parse(item.REQ_TO)
        let btw = parseInt((to - from) / (60 * 60 * 1000 * 24))
        for (let i = 0; i < btw + 1; i++) {
            // console.log("rashi", from+(i*86400000))
            // let x  = from+(i*86400000)
            let currentDate = new Date(from + (i * 86400000));
            let dayOfWeek = currentDate.getDay();
            if (dayOfWeek === 0) {
                // Skip if it's Sunday
                continue;
            }
            if (item.REQ_STATUS == "PENDING") {
                finalArray.push({
                    title: item.REASON, start: from + (i * 86400000),
                    className: "bg-purple"
                })

            } else if (item.REQ_STATUS == "APPROVED") {
                finalArray.push({
                    title: item.REASON, start: from + (i * 86400000),
                    className: "bg-success"
                })

            } else {
                finalArray.push({
                    title: item.REASON, start: from + (i * 86400000),
                    className: "bg-info"
                })

            }
        }
    })

    // console.log("finalArrayfinalArrayfinalArray",finalArray)
    // function helper(reqData){

    // }


    let x = new Date("2024-09-23")
    let y = new Date("1727049600000")
    console.log("dateeeee", calendarData[0], new Date(y))
    const defaultEvents = [
        {
            title: "Event Name 4",
            start: Date.now() - 338000000,
            className: "bg-purple",
        },
        {
            title: "Test Event 1",
            start: Date.now(),
            end: Date.now(),
            className: "bg-success",
        },
        {
            title: "Test Event 2444",
            start: 1727116200000,
            className: "bg-info",
        },
        {
            title: "Test Event 3555",
            start: Date.now() + 338000000,
            className: "bg-primary",
        },
    ];
    return (

        <>
            <div className="page-wrapper p-0 px-4">
                <div className=""> {/*content container-fluid*/}
                    {/* Page Header */}
                    <div className="page-header">
                    </div>
                    {/* /Page Header */}
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card mb-0">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-end mb-3">
                                                {/* Dropdowns */}
                                                {/* <div className="d-flex">
                                                    <select className="form-select w-auto" aria-label="Dropdown 2" style={{ maxWidth: '90px' }}
                                                        value={leaveType}
                                                        onChange={handleLeaveTypeChange}>
                                                        <option value="LEAVE">Leave</option>
                                                        <option value="WFH">Work From Home</option>
                                                        <option value="OFFICIAL_TOUR">Office Tour</option>
                                                    </select>
                                                    <select className="form-select w-auto ms-2" aria-label="Dropdown 1" style={{ maxWidth: '90px' }}
                                                        value={status}
                                                        onChange={handleStatusChange}>
                                                        <option value="PENDING">Pending</option>
                                                        <option value="APPROVED">Approved</option>
                                                        <option value="REJECT">Rejected</option>
                                                    </select>
                                                </div> */}
                                            </div>
                                            {/* Calendar */}
                                            <div id="calendar" />

                                            {/* <LeaveFilter
                                                setAppType={setAppType} 
                                                setAppStatus={setAppStatus}
                                                setMonthNo={setMonthNo}
                                                setDayNo={setDayNo}
                                            /> */}
                                            
                                            <FullCalendar
                                                plugins={[
                                                    dayGridPlugin,
                                                    timeGridPlugin,
                                                    interactionPlugin,
                                                ]}
                                                datesSet={(arg) => {
                                                    console.log(arg) //starting visible date
                                                    console.log(arg.end) //ending visible date
                                                  }} 
                                                headerToolbar={{
        
                                                    left: "prev,next today",
                                                    center: "title",
                                                    right: "dayGridMonth,timeGridDay",
                                                }}
                                                
                                                navLinkDayClick={(day)=>{
                                                    console.log("datta clicked",day)
                                                }}
                                                initialView="dayGridMonth"
                                                editable={true}
                                                selectable={true}
                                                selectMirror={true}
                                                dayMaxEvents={true}
                                                weekends={weekendsVisible}
                                                initialEvents={finalArray.length > 0 ? finalArray : []}
                                                
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <CalendarModal />
                </div>
            </div>
        </>
    )
}

export default PersonCalender
