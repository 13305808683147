import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

const EmployeeListFilter = ({setFilter_id, setFilter_name, setFilter_desg, filter_id, filter_name, filter_desg }) => {

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };
  // const [inputEmp_id, setInputEmp_id] = useState("");
  // const [inputEmp_name, setInputEmp_name] = useState("");
  const [focused, setFocused] = useState(false);
  const [focused1, setFocused1] = useState(false);
  const [focused2, setFocused2] = useState(false);

  const handleLabelClick = () => {
    setFocused(true);
  };

  const handleLabelClick2 = () => {
    setFocused1(true);
  };

  const handleLabelClick3 = () => {
    setFocused2(true);
  };

  const handleInputBlur = () => {
    if (filter_id === "") {
      setFocused(false);
    }
  };

  const handleInputBlur2 = () => {
    if (filter_name === "") {
      setFocused1(false);
    }
  };

  const handleInputBlur3 = () => {
    if (filter_desg === "") {
      setFocused2(false);
    }
  };

  const handleInputChange = (e) => {
    // const value = e.target.value;
    setFilter_id(e.target.value);
    if (e.target.value !== "" && !focused) {
      setFocused(true);
    }
    // console.log(filter_id);
  };

  const handleInputChange2 = (e) => {
    // const value = e.target.value;
    setFilter_name(e.target.value);
    if (e.target.value !== "" && !focused1) {
      setFocused1(true);
    }
    // console.log(filter_name)
  };

  const handleInputChange3 = (e) => {
    // const value = e.target.value;
    setFilter_desg(e.target.value);
    if (e.target.value !== "" && !focused2) {
      setFocused2(true);
    }
    // console.log(filter_desg)
  };

  return (
    <div>
      <div className="row filter-row">
        <div className="col-sm-6 col-md-4">
          <div
            className={
              focused || filter_id !== ""
                ? "input-block form-focus focused"
                : "input-block form-focus"
            }
          >
            <input
              type="text"
              className="form-control floating"
              value={filter_id}
              onFocus={handleLabelClick}
              onBlur={handleInputBlur}
              onChange={handleInputChange}
            />
            <label className="focus-label" onClick={handleLabelClick}>
              Employee ID
            </label>
          </div>
        </div>
        <div className="col-sm-6 col-md-4">
          <div
            className={
              focused1 || filter_name !== ""
                ? "input-block form-focus focused"
                : "input-block form-focus"
            }
          >
            <input
              type="text"
              className="form-control floating"
              value={filter_name}
              onFocus={handleLabelClick2}
              onBlur={handleInputBlur2}
              onChange={handleInputChange2}
            />
            <label className="focus-label" onClick={handleLabelClick2}>
              Employee Name
            </label>
          </div>
        </div>
        <div className="col-sm-6 col-md-4">
          <div
            className={
              focused2 || filter_name !== ""
                ? "input-block form-focus focused"
                : "input-block form-focus"
            }
          >
            <input
              type="text"
              className="form-control floating"
              value={filter_desg}
              onFocus={handleLabelClick3}
              onBlur={handleInputBlur3}
              onChange={handleInputChange3}
            />
            <label className="focus-label" onClick={handleLabelClick3}>
              Designation
            </label>
          </div>
        </div>
        {/* <div className="col-sm-6 col-md-4">
          <div className="input-block form-focus select-focus">
            <Select
              options={employee}
              styles={customStyles}
              placeholder="Select Designation"
              value={""}
            />
            <label className="focus-label">Designation</label>
          </div>
        </div> */}
        {/* <div className="col-sm-6 col-md-3">                       // yash's change
          <Link to="#" className="btn btn-success btn-block w-100">
            Search
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default EmployeeListFilter;
